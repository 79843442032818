<!--
 * @Date: 2021-02-07 14:17:29
 * @LastEditors: frank
 * @LastEditTime: 2021-05-13 22:12:44
 * @FilePath: /shop_frontend/src/views/freightTemplate/list.vue
 * @Description: Description
-->
<template>
  <div class="page">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="pagination"
          bordered
        >
        <span slot="icon" slot-scope="text, record">
          <img :src="record.icon_url" alt="" style="width: 100px; height: 100px; display: inline-block;">
        </span>
          <span slot="action" slot-scope="text, record">
            <div class="action_custom">
              <a-icon type="edit" @click="edit(record)" />
              <a-icon type="delete" @click="deleteHandle(record)" />
            </div>
          </span>
        </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    align: 'center'
  },
  {
    title: "初始重量",
    dataIndex: "initial_weight",
    key: "initial_weight",
    align: 'center'
  },
  {
    title: "初始金额",
    dataIndex: "initial_amount",
    key: "initial_amount",
    align: 'center'
  },
  {
    title: "后续增加重量",
    dataIndex: "subsequent_weight",
    key: "subsequent_weight",
    align: 'center'
  },
  {
    title: "后续增加金额",
    dataIndex: "subsequent_amount",
    key: "subsequent_amount",
    align: 'center'
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: 'center'
  },
];

import Cookies from 'js-cookie'
import {mapMutations, mapState} from 'vuex'

export default {
  name: "freightTemList",
  data() {
    return {
      loading: false,
      dataSource: [],
      columns,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      imageUrl: ''
    };
  },
  created() {
    this.initTable();
  },
  computed: {
    params() {
      return { page: this.page, search: this.searchVal };
    },
    headers () {
      return {'X-CSRFToken': Cookies.get('csrftoken')}
    },
    ...mapState(['isReload']),
  },
  watch: {
    isReload (nVal) {
      if (nVal) {
        this.initTable();
        this.setReload(false)
      }
    },
  },
  methods: {
    ...mapMutations(['setReload']),
    changeTable(pagination) {
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios.get("/freight_templates/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    edit(record) {
      this.visible = true;
      this.$axios.get(`/freight_templates/${record.id}/`).then((res) => {
        console.log(res, 'edit')
        this.$router.replace({name: 'freightTemplate', query: {
          isEdit: true,
          form: JSON.stringify(res)
        }})
      });
    },
    deleteHandle(record) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          console.log("OK");
          _this.$axios.delete(`/freight_templates/${record.id}/`).then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    add(record) {
      console.log(record);
    },
  },
};
</script>
<style lang="less" scoped>
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

